import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import BlogRollZh from '../components/BlogRollZh'
import Carousel from '../components/Carousel'
import { useIntl, FormattedMessage } from 'react-intl'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  carousel,
  mainpitch,
  description,
  intro,
  locale,
}) => (
  <div>
    <Carousel gridItems={carousel} />
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="column">
                    <p className="subtitle">{mainpitch.description}</p>
                    <p className="subtitle">{description}</p>
                  </div>
                </div>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    <FormattedMessage id="footer.latest" />
                  </h3>

                  {locale === 'zh' ? <BlogRollZh /> : <BlogRoll />}

                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to={`/${locale}/blog/`}>
                      <FormattedMessage id="home.readMore" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  carousel: PropTypes.array,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  locale: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const locale =
    typeof window !== `undefined`
      ? window.location.pathname.split('/')[1]
      : 'zh'

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        carousel={frontmatter.carousel}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        locale={locale}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        carousel {
          image {
            childImageSharp {
              fluid(maxWidth: 9999, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        mainpitch {
          title
          description
        }
        description
      }
    }
  }
`
