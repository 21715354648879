import React from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const CarouselItemTemplate = ({ image }) => {
  return (
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `center`,
        backgroundAttachment: `fixed`,
      }}
    ></div>
  )
}

CarouselItemTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const IndexPageCarousel = ({ gridItems }) => (
  <Carousel>
    {gridItems.map((item) => (
      <Carousel.Item>
        <CarouselItemTemplate
          image={item.image}
          heading={item.heading}
          subheading={item.subheading}
        />
      </Carousel.Item>
    ))}
  </Carousel>
)

export default IndexPageCarousel
